import { inject }                           from 'aurelia-framework';
import { BooleanOption }                    from 'modules/administration/models/boolean-option';
import { BooleanStatus }                    from 'modules/administration/models/boolean-status';
import { RoleType }                         from 'modules/administration/models/role-type';
import { UserEntityTeamLot }                from 'modules/administration/models/user-entity-team-lot';
import { UserEntityTeamLotType }            from 'modules/administration/models/user-entity-team-lot-type';
import { RolesRepository }                  from 'modules/administration/roles/services/repository';
import { UserEntityTeamLotsRepository }     from 'modules/administration/users/user-entity-team-lot/services/repository';
import { UserEntityTeamLotTypesRepository } from 'modules/administration/users/user-entity-team-lot/user-entity-team-lot-types/services/repository';
import { LotInterventionsRepository }       from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { EntitiesRepository }               from 'modules/management/concessions/entities/services/repository';

@inject(EntitiesRepository, LotInterventionsRepository, RolesRepository, UserEntityTeamLotTypesRepository, UserEntityTeamLotsRepository)
export class UserEntityTeamLotFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        user_entity_team_lot_status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param entitiesRepository
     * @param lotInterventionsRepository
     * @param rolesRepository
     * @param userEntityTeamLotTypesRepository
     * @param userEntityTeamLotsRepository
     */
    constructor(entitiesRepository, lotInterventionsRepository, rolesRepository, userEntityTeamLotTypesRepository, userEntityTeamLotsRepository) {
        this.entitiesRepository               = entitiesRepository;
        this.lotInterventionsRepository       = lotInterventionsRepository;
        this.rolesRepository                  = rolesRepository;
        this.userEntityTeamLotTypesRepository = userEntityTeamLotTypesRepository;
        this.userEntityTeamLotsRepository     = userEntityTeamLotsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {UserEntityTeamLot}
     */
    model() {
        let model = new UserEntityTeamLot();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         6,
            remoteSource: () => this.entitiesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        if (viewModel.model.identifier === null || viewModel.model.identifier === '') {
                            this.userEntityTeamLotsRepository.nextIdentifierSuggestion(newValue).then((response) => {
                                viewModel.model.identifier = response;
                            });
                        }

                        return this.entitiesRepository.teams(newValue).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            size:         6,
            remoteSource: () => {
                return viewModel.model.entity_id
                    ? this.entitiesRepository.teams(viewModel.model.entity_id)
                    : Promise.resolve([]);
            },
            attributes:   {
                disabled: readonly || !viewModel.model.entity_id,
            },
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            remoteSource: () => this.lotInterventionsRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.type',
            size:         6,
            remoteSource: () => this.userEntityTeamLotTypesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue && newValue !== UserEntityTeamLotType.HIGHWAY_OPERATOR) {
                        this.use_extra_permission_id.hidden     = false;
                        this.use_extra_permission_id.label      = this._handleExtraPermissionLabel(newValue);
                        viewModel.model.use_extra_permission_id = BooleanOption.NO;
                    } else {
                        this.use_extra_permission_id.hidden     = true;
                        viewModel.model.use_extra_permission_id = null;
                    }
                },
            ],
        };

        this.user_entity_team_lot_status_id = {
            type:       'boolean-statuses-select',
            key:        'user_entity_team_lot_status_id',
            label:      'form.field.status',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.use_extra_permission_id = {
            type:       'boolean-options-select',
            key:        'use_extra_permission_id',
            label:      this._handleExtraPermissionLabel(viewModel.model.type_id),
            size:       6,
            hidden:     viewModel.model.type_id === UserEntityTeamLotType.HIGHWAY_OPERATOR,
            attributes: {
                disabled: readonly,
            },
        };

        this.identifier = {
            type:       'text',
            key:        'identifier',
            label:      'form.field.identifier',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.roles = {
            type:         'duallistbox',
            key:          'roles',
            label:        'form.field.roles',
            size:         12,
            required:     false,
            remoteSource: () => this.rolesRepository.activeByType(RoleType.CONSTRUCTION),
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.entity_id, this.team_id],
            [this.lot_intervention_id, this.identifier],
            [this.type_id, this.user_entity_team_lot_status_id],
            [this.use_extra_permission_id],
            [this.roles],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Handles extra permission label
     *
     * @param typeId
     * @return {string}
     */
    _handleExtraPermissionLabel(typeId) {
        return typeId === UserEntityTeamLotType.BUILDER ? 'form.field.site-manager?' : 'form.field.view-builders-data?';
    }
}
